<template>
  <q-menu class="notification-menu q-pl-sm" fit :offset="[100, 20]">
    <q-infinite-scroll
      @load="onLoad"
      :offset="250"
      ref="infiniteScrollRef"
      v-if="notifications && notifications.length > 0"
    >
      <template v-for="(item, i) in notifications" :key="item.id">
        <q-item
          class="q-gutter-x-md q-py-md items-center relative-position"
          clickable
          @click="openNotification(item)"
        >
          <q-badge
            color="red"
            class="read-flag"
            rounded
            v-if="item.status === 0"
          />
          <q-avatar
            icon="icon-user-line"
            color="primary"
            text-color="white"
            size="lg"
          />
          <figcaption :class="{ 'col-grow': q.screen.width <= 520 }">
            <h6 class="q-my-sm">{{ item.message }}</h6>
            <div class="flex justify-between">
              <span class="time">{{ item.time }}</span>
              <span class="date">{{ item.data }}</span>
            </div>
          </figcaption>
        </q-item>
        <q-separator v-if="i < notifications.length - 1" />
      </template>
      <template v-slot:loading>
        <div class="row justify-center q-my-md">
          <q-spinner-dots color="primary" size="40px" />
        </div>
      </template>
    </q-infinite-scroll>
    <q-item class="q-py-md items-center" v-else>
      <p class="q-my-sm text-h6 text-weight-bold">لا توجد إشعارات حالياً</p>
    </q-item>
  </q-menu>
</template>

<script lang="ts" setup>
import { handleErrorFunc } from '@/boot/error-handler';
import {
  getNotifications,
  viewNotification,
} from '@/services/notification-service';
import { useNotificationsStore } from '@/stores/notifications';
import { INotification } from '@/types/INotifications';
import { QInfiniteScroll, useQuasar } from 'quasar';
import { computed, onMounted, ref } from 'vue';

const notificationStore = useNotificationsStore();
const notifications = computed(() => notificationStore.notifications || []);
const currentPage = ref(1);
const infiniteScrollRef = ref<QInfiniteScroll | null>(null);
const q = useQuasar();

onMounted(async () => {
  await getAllNotifications();
});

const getAllNotifications = async () => {
  try {
    const response = await getNotifications(currentPage.value);
    notificationStore.setNotifications([
      ...notificationStore.notifications,
      ...response.data.notifications,
    ]);
    return response.data.notifications.length;
  } catch (e: any) {
    handleErrorFunc(e);
  }
};
const onLoad = async (index: number, done: () => void) => {
  currentPage.value++;
  const noOfNotification = await getAllNotifications();
  if (noOfNotification !== 0) {
    done();
  } else {
    infiniteScrollRef.value!.stop();
  }
};

const openNotification = (notification: INotification) => {
  try {
    viewNotification(notification.id);
    const notificationIndex = notifications.value.findIndex(
      (n: any) => n.id === notification.id,
    );
    if (notificationIndex !== -1) {
      notifications.value[notificationIndex].status = 1;
    }
    if (notification.has_action && notification.action) {
      window.open(notification.action, '_blank');
    }
  } catch (e: any) {
    handleErrorFunc(e);
  }
};
</script>

<style lang="scss">
.q-badge {
  &.read-flag {
    position: absolute;
    left: 10px;
    top: 10px;
    margin-right: unset;
  }
}
@media screen and (max-width: 520px) {
  .q-menu {
    &.notification-menu {
      border-radius: 8px;
      min-width: 100% !important;
      h6 {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}
</style>
