import { ref } from 'vue';
import { defineStore } from 'pinia';
import { INotification } from '@/types/INotifications';

export const useNotificationsStore = defineStore('notificationsStore', () => {
  const alertsNumber = ref(0);
  const notifications = ref<INotification[]>([]);

  const setNotifications = (data: INotification[]) => {
    notifications.value = data;
  };

  const pushtNotifications = (data: INotification) => {
    if(!notifications.value.some((notific: INotification) => notific.id === data.id && notifications.value.length > 0)) {
      notifications.value.unshift(data);
    }
  };

  const setAlertsNumber = (data: number) => {
    alertsNumber.value = data;
  };

  return {
    alertsNumber,
    notifications,
    setNotifications,
    pushtNotifications,
    setAlertsNumber,
  };
});
