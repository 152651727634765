<template>
  <q-layout view="lHh lpr lFf" v-if="isHydrated" :class="{
    'bg-f5':
      route.name === 'ProfilePage' ||
      route.name === 'ResultPage' ||
      route.name === 'BusinessProfilePage' ||
      route.name === 'SearchResultsPage' ||
      route.name === 'ReviewPage',
  }">
    <Header />
    <q-page-container>
      <q-page :style-fn="myTweak">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" :key="route.fullPath" />
          </transition>
        </router-view>
      </q-page>
    </q-page-container>
    <Footer />
    <WhatsappBtn />
  </q-layout>
</template>

<script setup lang="ts">
import Header from '@/components/ui/HeaderComponent.vue';
import Footer from '@/components/ui/FooterComponent.vue';
import WhatsappBtn from '@/components/ui/WhatsappBtn.vue';
import { useHydration, useMeta, QLayout, QPageContainer, QPage } from 'quasar';
import { useRoute } from 'vue-router';
import { computed } from 'vue';



const { isHydrated } = useHydration();
const route = useRoute();


const computedMetaTags = computed(() => route.meta)
useMeta(computedMetaTags.value!);


const myTweak = (offset: number) => {
  // "offset" is a Number (pixels) that refers to the total
  // height of header + footer that occupies on screen,
  // based on the QLayout "view" prop configuration

  // this is actually what the default style-fn does in Quasar
  return { minHeight: offset ? `calc(100vh - ${offset}px - 0px)` : '100vh' };
};
</script>
