<template>
  <q-no-ssr>
    <q-header class="bg-white">
      <q-toolbar class="q-pa-none header-container">
        <div class="row justify-between items-center full-width">
          <img
            class="logo cursor-pointer"
            loading="lazy"
            src="../../assets/images/logo.svg"
            alt="Akwadlogo"
            width="135"
            height="40"
            @click="goToHomePage"
          />
          <div class="q-ml-lg text-dark text-bold col-grow">
            <img
              class="flag"
              src="~assets/images/sa-flag.svg"
              alt="السعودية"
              width="32"
              height="32"
              title="طوِّر بفخر في السعودية"
            />
            <span v-if="gtSixFourZero"
              >طوِّر بفخر في
              <span class="text-green-8">السعودية</span></span
            >
          </div>
          <template v-if="gtOneTwoFourTwo">
            <div class="flex col-grow justify-end items-center">
              <div style="width: 28%" class="q-mr-xl" v-if="route.path !== '/'">
                <HeaderSearchBar />
              </div>
              <div class="flex items-center">
                <!-- Chrome Extension Icon -->
                <q-btn  class="text-dark" label="حمل أداة اكواد" icon="img:/chrome.store.svg" type="a" href="https://chromewebstore.google.com/detail/akwad-ux-tool/moimamimapjopaapamoiciljeocjfddl"/>
                <div v-if="!loggedIn">
                  <q-btn
                    v-for="item of unAuthNavItems"
                    :key="item.label"
                    flat
                    :class="item.class"
                    :label="item.label"
                    size="md"
                    :to="item.to"
                    :aria-label="item.icon"
                  />
                </div>
                <template v-else>
                  <div class="flex q-gutter-sm">
                    <NotificationBtn />
                    <q-btn-dropdown
                      class="user-btn"
                      :label="userName"
                      :ripple="false"
                      icon="icon-user-line"
                      dropdown-icon="icon-chevron-down"
                      content-class="user-btn-menu"
                      aria-label="user-btn"
                    >
                      <q-list>
                        <template
                          v-for="item of authNavItems"
                          :key="item.label"
                        >
                          <q-item
                            clickable
                            v-close-popup
                            :to="item.to ?? item.to"
                            @click="item.click"
                            v-if="item.visible"
                          >
                            <q-item-section>
                              <q-item-label :class="item.class">{{
                                item.label
                              }}</q-item-label>
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-list>
                    </q-btn-dropdown>

                    <q-btn
                      :class="businessVerifyBtn.class"
                      :label="businessVerifyBtn.label"
                      size="md"
                      :to="businessVerifyBtn.to"
                      aria-label="orders-btn"
                    />
                  </div>
                </template>
              </div>
            </div>
          </template>
          <template v-else>
            <NotificationBtn v-if="loggedIn"/>

            <q-btn
              flat
              @click="drawer = !drawer"
              round
              dense
              icon="menu"
              color="primary"
              size="lg"
              aria-label="menu-btn"
            />
          </template>
        </div>
      </q-toolbar>
    </q-header>
    <q-drawer
      v-model="drawer"
      :breakpoint="1242"
      elevated
      class="bg-white q-pa-md"
    >
      <q-scroll-area class="fit">
        <div class="q-pa-sm">
          <!-- Logo and Close Btn -->
          <div class="row items-center justify-between">
            <q-img
              class="logo cursor-pointer"
              src="/logo.svg"
              alt="Akwadlogo"
              @click="goToHomePage"
              width="120px"
              height="auto"
              fit="contain"
              loading="lazy"
            />

            <q-icon
              name="close"
              size="sm"
              flat
              color="primary"
              class="cursor-pointer"
              @click="drawer = false"
            />
          </div>
          <q-list class="q-mt-lg" v-if="drawer">
            <template v-for="item of drawerNavItems" :key="item.label">
              <q-item
                clickable
                :to="item.to"
                v-if="item.visible"
                @click="item.click"
              >
                <q-item-section avatar>
                  <q-avatar :icon="item.icon" text-color="primary" />
                </q-item-section>

                <q-item-section>
                  <q-item-label class="akwad-medium text-dark text-body1">{{
                    item.label
                  }}</q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-list>
        </div>
      </q-scroll-area>
    </q-drawer>

    <q-dialog v-model="logoutDialoge" position="standard" persistent>
      <q-card
        class="bg-slate-700 shadow-24"
        style="width: 650px; max-width: 90vw; padding: 5px"
      >
        <q-card-section style="padding-top: 0">
          <div class="text-h6 akwad-medium">هل تريد تسجيل الخروج ؟</div>
        </q-card-section>

        <q-card-actions align="right" class="akwad-medium">
          <q-btn
            label="إلغاء"
            outline
            color="grey"
            v-close-popup
            icon-right="icon-x1"
            class="popup-btn"
            aria-label="cancel-btn"
          />
          <q-btn
            label="تأكيد"
            color="primary"
            @click="onLogout"
            icon-right="icon-check"
            class="popup-btn"
            icon-xs
            aria-label="confirm-btn"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-no-ssr>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { logout } from '@/services/auth-service';
import MyToaster from '@/utils/toaster';
import { useQuasar, QCard, QCardSection, QCardActions, QDialog, QNoSsr, QList, QToolbar, QHeader, QBtnDropdown, QBtn, QDrawer, QScrollArea, QAvatar, QIcon, QItem, QItemLabel, QItemSection } from 'quasar';
import HeaderSearchBar from './HeaderSearchBar.vue';
import NotificationBtn from '../notification/NotificationBtn.vue';

const authStore = useAuthStore();
const loggedIn = computed(() => authStore.isLoggedIn);
const is_agent = ref(authStore.is_agent);
const drawer = ref<boolean>(false);
const gtSixFourZero = computed(() => $q.screen.width > 640)
const gtOneTwoFourTwo = computed(() => $q.screen.width > 1242)

const businessVerifyBtn = {
  id: 'drawer-business-verify',
  class: 'primary_btn rounded-8 business-btn text-bold',
  label: 'للأعمـــــال',
  icon: 'icon-briefcase1',
  to: '/order',
  click: () => {
    drawer.value = false;
  },
  visible: true,
};
const unAuthNavItems = ref([
  {
    class:
      'text-primary text-weight-bolder login-btn rounded-8 business-btn font-bold q-mx-sm',
    label: 'تسجيل دخول',
    icon: '',
    to: '/login',
  },
  {
    class: 'primary_btn rounded-8 business-btn text-bold',
    label: 'للأعمـــــال',
    icon: '',
    to: '/order',
  },
]);

const authNavItems = ref([
  {
    class: 'akwad-medium',
    label: 'التقييمات',
    icon: '',
    to: '/profile',
    click: () => null,
    visible: loggedIn.value,
  },
  {
    class: 'akwad-medium',
    label: 'ملف الأعمال',
    icon: '',
    to: '/business',
    click: () => null,
    visible: loggedIn.value && is_agent.value == 1,
  },
  {
    class: 'akwad-medium',
    label: 'الإعدادات',
    icon: '',
    to: '/settings',
    click: () => null,
    visible: loggedIn.value,
  },
  {
    class: 'akwad-medium',
    label: 'تسجيل الخروج',
    icon: '',
    to: null,
    click: () => {
      logoutDialoge.value = true;
    },
    visible: loggedIn.value,
  },
]);

const drawerNavItems = computed(() => [
  {
    id: 'drawer-login',
    class: '',
    label: 'تسجيل الدخول',
    icon: 'icon-logout',
    to: '/login',
    click: () => {
      drawer.value = false;
    },
    visible: !loggedIn.value,
  },
  businessVerifyBtn,
  // {
  //   id: 'drawer-notifications',
  //   class: '',
  //   label: 'الإشعارات',
  //   icon: 'icon-notification',
  //   to: '',
  //   visible: loggedIn.value,
  // },
  {
    id: 'drawer-ratings',
    class: '',
    label: 'التقييمات',
    icon: 'icon-user',
    to: '/profile',
    click: () => {
      drawer.value = false;
    },
    visible: loggedIn.value,
  },
  {
    id: 'drawer-business',
    class: '',
    label: 'ملف الأعمال',
    icon: 'icon-user',
    to: '/business',
    click: () => {
      drawer.value = false;
    },
    visible: loggedIn.value && is_agent.value == 1,
  },
  {
    id: 'drawer-settings',
    class: '',
    label: 'الإعدادات',
    icon: 'icon-settings',
    to: '/settings',
    click: () => {
      drawer.value = false;
    },
    visible: loggedIn.value,
  },
  {
    id: 'drawer-logout',
    class: '',
    label: 'تسجيل الخروج',
    icon: 'icon-logout',
    to: null,
    click: () => {
      logoutDialoge.value = true;
    },
    visible: loggedIn.value,
  },
]);
const router = useRouter();
const route = useRoute();
const userName = computed(
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  () => `${authStore.user?.first_name!}  ${authStore.user?.last_name}`,
);
const logoutDialoge = ref<boolean>(false);

const $q = useQuasar();

const goToHomePage = () => {
  router.push({ name: 'HomePage' });
  drawer.value = false;
};

watch(
  ()=>authStore.is_agent,
  ()=> {is_agent.value = authStore.is_agent;
    authNavItems.value = [...authNavItems.value];
  },
  {
    immediate: true,
    deep: true,
  }
)

const onLogout = async () => {
  try {
    const response = await logout();
    if (response) {
      authStore.logout();
      nextTick(() => {
        router.push('/');
        drawer.value = false;
        logoutDialoge.value = false;
        $q.localStorage.clear();
      });
      MyToaster.success('تم تسجل الخروج بنجاح');
    }
  } catch (error: any) {
    authStore.logout();
    nextTick(() => {
      router.push('/');
    });
    drawer.value = false;
    logoutDialoge.value = false;
    MyToaster.success('تم تسجل الخروج بنجاح');
  }
};
</script>
