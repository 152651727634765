<template>
  <q-select
    name="header-search-bar"
    id="header-search-bar"
    v-model="searchTerm"
    transition-show="jump-up"
    transition-hide="jump-up"
    :label="searchTerm ? undefined : 'جرّب أدخل الموقع هنا...'"
    :hide-bottom-space="true"
    :hide-hint="true"
    use-input
    hide-selected
    fill-input
    outlined
    dense
    @filter="filterFn"
    :options="resultArr"
    emit-value
    map-options
    option-label="url"
    option-value="url"
    @input-value="setUrl($event)"
    @update:model-value="onSelect"
    @keydown.enter.prevent="getAllSearchResults"
    dropdown-icon="search"
    hide-dropdown-icon
    :loading="autocompleteLoader"
    behavior="menu"
    popup-no-route-dismiss
    input-debounce="0"
  >
    <template v-slot:append>
      <q-avatar class="cursor-pointer" @click="getAllSearchResults">
        <q-icon class="icon-search" size="sm" />
      </q-avatar>
    </template>
    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps">
        <q-item-section>
          <q-item-label>{{ scope.opt.name }}</q-item-label>
          <q-item-label caption>{{ scope.opt.url }}</q-item-label>
        </q-item-section>
      </q-item>
    </template>
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey"> لا يوجد نتائج </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { handleErrorFunc } from '@/boot/error-handler';
import { autoComplete, search } from '@/services/search-service';
import { useSearchStore } from '@/stores/search';
import { useLoaderStore } from '@/stores/loader';
import { ISearchAutoComplete } from '@/types/IHomeSearch';
import MyToaster from '@/utils/toaster';
import { QSelect, QAvatar, QIcon, QItem, QItemLabel, QItemSection } from 'quasar';

const router = useRouter();
const route = useRoute();
const akwadLoader = useLoaderStore();
const searchStore = useSearchStore();
const searchTerm = ref<string>('');
const autocompleteLoader = ref<boolean>(false);
const resultArr = ref<ISearchAutoComplete[]>([]);

const minChars = 3;
let cancelToken: AbortController | null = null;

const filterFn = async (e: string, update: any) => {
  searchTerm.value = e;
  if (searchTerm.value.length < minChars) {
    update(() => {
      resultArr.value = [];
      autocompleteLoader.value = false;
    });
    return;
  }
  if (cancelToken) {
    cancelToken.abort();
  }
  cancelToken = new AbortController();
  update(async () => {
    autocompleteLoader.value = true;
    try {
      const results = await autoComplete(searchTerm.value, {
        signal: cancelToken!.signal,
      });
      resultArr.value = results.data;
      autocompleteLoader.value = false;
    } catch (error: any) {
      autocompleteLoader.value = false;
    }
  });
};

const setUrl = (url: string) => {
  searchTerm.value = url;
};

const onSelect = async () => {
  setTimeout(async () => {
    try {
      akwadLoader.show();
      const res = await search(searchTerm.value);
      searchStore.setKey(res.data.key);
      searchStore.setUrl(res.data.url);
      const targetPath = `/result/${res.data.origin_link}`;
      if (router.currentRoute.value.path === targetPath) {
        window.location.reload();
      } else {
        await router.push(`/result/${res.data.origin_link}?page=1`);
      }
    } catch (error: any) {
      handleErrorFunc(error);
    }
  }, 100);
};

const getAllSearchResults = async () => {
  if(!searchTerm.value) {
    MyToaster.error('مابيصير كلمة البحث تقل عن 3 حروف');
    return;
  };
  try {
    akwadLoader.show();
    if (searchTerm.value.includes('http')) {
      await router.push(`/result/${searchTerm.value}?page=1`);
    } else {
      router.push({
        name: 'SearchResultsPage',
        query: {
          term: searchTerm.value,
          page: '1',
        },
      });
    }
  } catch (error: any) {
    handleErrorFunc(error);
  }
};

watch(
  () => route.path,
  () => {
    searchTerm.value = '';
    resultArr.value = [];
  },
);
</script>
