<template>
  <div class="wpp-per">
    <div id="wpp-fix" class="right shake">
      <button @click="openClosePopup" :class="{ 'hide-this': showPopup }" id="wpp-link" class="wpp-form wpp-link">
        <svg height="682pt" viewBox="-23 -21 682 682.667" width="682pt"><path d="M544.387 93.008C484.512 33.063 404.883.035 320.05 0 145.246 0 2.98 142.262 2.91 317.113c-.024 55.895 14.577 110.457 42.331 158.551L.25 640l168.121-44.102c46.324 25.27 98.477 38.586 151.55 38.602h.134c174.785 0 317.066-142.273 317.132-317.133.036-84.742-32.921-164.418-92.8-224.36zM320.05 580.94h-.11c-47.296-.02-93.683-12.73-134.16-36.742l-9.62-5.715-99.766 26.172 26.628-97.27-6.27-9.972c-26.386-41.969-40.32-90.476-40.296-140.281.055-145.332 118.305-263.57 263.7-263.57 70.406.023 136.59 27.476 186.355 77.3s77.156 116.051 77.133 186.485C583.582 462.69 465.34 580.94 320.05 580.94zm144.586-197.418c-7.922-3.968-46.883-23.132-54.149-25.78-7.258-2.645-12.547-3.962-17.824 3.968-5.285 7.93-20.469 25.781-25.094 31.066-4.625 5.29-9.242 5.953-17.168 1.985-7.925-3.965-33.457-12.336-63.726-39.332-23.555-21.012-39.457-46.961-44.082-54.89-4.617-7.938-.04-11.813 3.476-16.173 8.578-10.652 17.168-21.82 19.809-27.105 2.644-5.29 1.32-9.918-.664-13.883-1.977-3.965-17.824-42.969-24.426-58.84-6.437-15.445-12.965-13.36-17.832-13.601-4.617-.231-9.902-.278-15.187-.278-5.282 0-13.868 1.98-21.133 9.918-7.262 7.934-27.73 27.102-27.73 66.106s28.394 76.683 32.355 81.972c3.96 5.29 55.879 85.328 135.367 119.649 18.906 8.172 33.664 13.043 45.176 16.695 18.984 6.031 36.254 5.18 49.91 3.14 15.226-2.277 46.879-19.171 53.488-37.68 6.602-18.51 6.602-34.374 4.617-37.683-1.976-3.304-7.261-5.285-15.183-9.254zm0 0" fill-rule="evenodd"></path></svg>
      </button>
      <div class="form-wpp">
        <div class="head-wpp">
          <img src="../../assets/images/small-logo.svg" alt="akwad logo" loading="lazy" width="100" class="logo-wpp">
          <q-btn  icon="close" color="gray-4"  @click="openClosePopup" :class="{ 'hide-this': showPopup }" aria-label="whatsapp-btn"  class="btn-close">
          </q-btn>
        </div>
        <div class="form-content">
          <p class="buss-msg">
            ياهلا 👋 كيف نقدر نساعدك ؟
          </p>
          <a class="open-chat " href="https://wa.me/+966112222111" target="_blank">
            <img src="../../assets/images/send.png" alt="send" width="16" loading="lazy">
            <span>
              إفتح المحادثة
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  const showPopup = ref(false);
  const  openClosePopup = () => {
    showPopup.value = !showPopup.value;
  }
</script>

<style scoped lang="scss" >
@use "sass:color";
  /* Variables */
  $white: #fff;
  $black: #000;
  $whatsapp: #25D366;
  $whatsapp_900: #25D366;
  $warning: #f9e7b1;
  $error: #F44336;

  /* Mixins */
  @mixin MQ($canvas) {
    @media (max-width: $canvas) { @content; }
  }

  @mixin transition($args...) {
    @if length($args) == 0 {
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -ms-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
    } @else {
      -webkit-transition: $args;
      -moz-transition: $args;
      -ms-transition: $args;
      -o-transition: $args;
      transition: $args;
    }
  }

  @mixin transform($args...) {
    -webkit-transform: $args;
    -moz-transform: $args;
    -ms-transform: $args;
    -o-transform: $args;
    transform: $args;
  }

  @mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
      $animations: #{$animations + nth($animate, $i)};

      @if $i < $max {
        $animations: #{$animations + ", "};
      }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
  }

  @mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
      @content;
    }
    @-moz-keyframes #{$animationName} {
      @content;
    }
    @-o-keyframes #{$animationName} {
      @content;
    }
    @keyframes #{$animationName} {
      @content;
    }
  }

  /* Animations */
  @include keyframes(wiggle) {
    0% {
      @include transform(rotate(-3deg));
    }
    20% {
      @include transform(rotate(20deg));
    }
    40% {
      @include transform(rotate(-15deg));
    }
    60% {
      @include transform(rotate(5deg));
    }
    90% {
      @include transform(rotate(-1deg));
    }
    100% {
      @include transform(rotate(0));
    }
  }

  @include keyframes(pulse) {
    0% {
      @include transform(scale(1));
    }
    50% {
      @include transform(scale(1.6));
    }
    100% {
      @include transform(scale(1.7));
      opacity: 0;
    }
  }

  #wpp-fix {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 12;

    .wpp-link {
      @include transition();
      background: $whatsapp;
      display: flex;
      z-index: 2;
      position: relative;
      padding: 15px;
      border: none;
      cursor: pointer;
      border-radius: 50%;
      box-shadow: 0px 3px 10px rgba($whatsapp, .56);
      @include transform(scale(1));

      & > span {
        display: none;
      }

      &:hover {
        @include transform(scale(1.05));
        background: color.scale($whatsapp, $lightness: -10%);
      }

      &.hide-this {
        opacity: 0;
        visibility: hidden;
        @include transform(scale(.5));
        &+.form-wpp {
          visibility: visible;
          opacity: 1;
          @include transform(scale(1));
          border-radius: 30px;
          &+.mask {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      &+.form-wpp {
        @include transition();
        position: absolute;
        box-shadow: 0 0 20px 0 rgba($black, .12);
        border-radius: 3px;
        bottom: 0;
        right: 0px;
        opacity: 0;
        overflow: hidden;
        @include transform(scale(0.2));
        border-radius: 50%;
        z-index: 1;
        visibility: hidden;
        transform-origin: bottom right;
        width: 100%;
        max-width: 300px;
        min-width: 350px;

        .form-content {
          @include transition();
          padding: 15px;
          float: left;
          position: relative;
          width: 100%;
          background-color: #e9e0d7;
          background-position: 50%;
          background-size: cover;
          background-image: url("../../assets/images/bg-whatsapp.png");
        }

        .buss-msg {
          background-color: $white;
          padding: 10px;
          border-radius: 0 12px 12px 12px;
          line-height: 1.3;
          font-size: 15px;
          max-width: 270px;
          margin: 0 0 10px;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            border-top: 5px solid $white;
            border-right: 5px solid $white;
            border-bottom: 5px solid transparent;
            border-left: 5px solid transparent;
            left: -8px;
            top: 0;
          }
        }
        .open-chat{
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap:12px;
          cursor: pointer;
          background: #25d366;
          width: max-content;
          padding-inline: 20px;
          border-radius: 15px;
          padding-block: 10px;
          color: #ffffff;
          font-weight: bold;
          margin-left: auto !important;
          margin-top: 21px;
        }

        .head-wpp {
          width: 100%;
          padding: 12px 8px;
          background: $whatsapp_900;
          text-align: end;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .btn-close {
            border-radius: 50%;
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    svg {
      width: initial;
      display: block;
      height: 30px;

      path {
        fill: $white;
      }
    }

    &.shake {
      .wpp-link {
        &::before {
          @include transition();
          background: rgba($whatsapp, .6);
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          border-radius: 50%;
          z-index: -1;
          top: 0px;
          right: 0;
          @include animation('pulse 2s infinite');
        }
      }

      svg {
        @include animation('1s wiggle ease infinite');
      }
    }

    @include MQ(767px) {
      bottom: 15px;

      svg {
        height: 30px;
      }

      .wpp-link {
        padding: 10px;
        &+.form-wpp {
          form {
            width: calc(300px - 40px);
            input[type="text"],
            input[type="email"]{
              font-size: .875rem;
            }
            .btn {
              button {
                font-size: .875rem;
              }
            }
          }
        }
      }
    }

    @include MQ(395px) {
      .wpp-link {
        &+.form-wpp {
          min-width: 290px;
        }
      }
    }

    .logo-wpp{
      margin-left: -15px;
      margin-block: -15px;
    }
  }

</style>

