<template>
  <div class="cursor-pointer flex flex-center notification-btn">
    <q-btn class="custom-round" @click="clearAlerts()" aria-label="notification_btn">
      <q-badge color="red" floating v-if="alertNumber > 0">{{ alertNumber }}</q-badge>
      <q-icon class="icon-bell round" color="primary" />
      <NotificationMenu />
    </q-btn>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, watchEffect } from 'vue';
import NotificationMenu from './NotificationMenu.vue';
import { PusherService } from '@/services/pusher-service';
import { useAuthStore } from '@/stores/auth';
import { getAlertsNumber, getNotifications, markAlertAsRead } from '@/services/notification-service';
import { useNotificationsStore } from '@/stores/notifications';
import { handleErrorFunc } from '@/boot/error-handler';
import { INotification } from '@/types/INotifications';
import { QIcon, QBadge } from 'quasar';

onMounted(async()=>{
  await getAlerts()
})

const notificationStore = useNotificationsStore();
const alertNumber = computed(() => notificationStore.alertsNumber);
const authStore = useAuthStore();
const loggedIn = computed(() => authStore.isLoggedIn);
const pusherService = PusherService.getInstance();


const getAlerts =  async () => {
  try{
   const res = await getAlertsNumber();
   notificationStore.setAlertsNumber(res.data.alert);
  }catch(e:any){
    handleErrorFunc(e);
  }
}

const getAllNotifications = async () => {
  try {
    const response = await getNotifications(1);
    notificationStore.setNotifications([ ...response.data.notifications ]);
    return response.data.notifications.length;
  } catch (e: any) {
    handleErrorFunc(e);
  }
};

const clearAlerts =  async () => {
  try{
   await markAlertAsRead();
   notificationStore.setAlertsNumber(0);
  }catch(e:any){
    handleErrorFunc(e);
  }
}



watchEffect(() => {
  if (loggedIn.value && authStore.token && authStore.id) {
    pusherService.initializePusher(authStore.token);
    const channel = pusherService.subscribeToUserChannel(authStore.id);

    channel?.bind('show-notification', async (data: INotification) => {
      // notificationStore.pushtNotifications(data);
      // notificationStore.setAlertsNumber(notificationStore.alertsNumber + 1);
      await getAlerts()
      await getAllNotifications();
    });
  }
});


onUnmounted(() => {
  pusherService.disconnect();
});
</script>

<style lang="scss" scoped>
.q-badge--floating{
  left: -3px;
  right: 0px;
  justify-self: start;
}
</style>
