<template>
  <q-no-ssr>
    <q-footer class="bg-transparent">
      <div class="text-center q-mb-md">
          <q-btn text-color="dark" target="_blank" href="https://wa.me/+966112222111" class="whatsapp-cta q-pa-md akwad-Black" rounded color="grey-3" icon="icon-whatsapp-color" dir="ltr" size="16px"
            label="011 2222 111" aria-label="icon-whatsapp-color" />
        </div>
      <div class="q-mx-auto flex justify-end q-pr-none items-baseline relative">
        <div class="footer-content row q-mx-auto items-center">
          <div class="dark-logo">
            <img src="../../assets/images/logo.svg" loading="lazy" alt="akwad" width="118" height="35" />
            <small class="text-bold q-mt-sm inline-block">أداة تقييم المواقع من أكواد</small>
            <div class="social-icons flex justify-center">
              <a :href="link.link" target="_blank" class="icon-social" v-for="link in socialLinks" :key="link.title" :title="link.title">
                <img :src="link.icon" width="25px" height="25px" :alt="link.title"/>
              </a>
            </div>
          </div>
          <div class="contact-text">
            <div class="text-subtitle1 text-bold">تواصل معنا</div>
            <ul>
              <li>
                <a href="mailto:info@akwad.sa">
                  <i class="icon icon-mail q-mr-xs"></i> <span class="inline-block">info@akwad.sa</span>
                </a>
                </li>
              <li><i class="icon icon-map-pin q-mr-xs"></i> السعودية، الرياض</li>
              <li><i class="icon icon-map-pin q-mr-xs"></i> السعودية، المنطقة الشرقية</li>
            </ul>
          </div>
        </div>
        <div class="line-border"></div>
        <img src="../../assets/images/footer.svg" loading="lazy" class="footer-img" alt="Akwad footer" width="100%" height="140" style="width: fit-content;" />
      </div>
      <div class="flex full-width q-pa-sm q-pa-md-md q-px-xl justify-center">
        <div> © 2025 أكواد . جميع الحقوق محفوظة &nbsp; | &nbsp;</div>
        <div class="br"></div>
        <span class="text-link" @click="push('/privacy')">سياسة الخصوصية</span>
        &nbsp; | &nbsp;
        <span class="text-link" @click="push('/terms-conditions')">سياسة الاستخدام</span>
      </div>
    </q-footer>
  </q-no-ssr>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import linkedinIcon from '@/assets/icons/social/linkedin.svg';
import tiktokIcon from '@/assets/icons/social/tiktok.svg';
import instagramIcon from '@/assets/icons/social/instagram.svg';
import xIcon from '@/assets/icons/social/x.svg';
import youtubeIcon from '@/assets/icons/social/youtube.svg';
import { QFooter, QNoSsr } from 'quasar';

const { push } = useRouter();
const socialLinks = [
  {
    icon: linkedinIcon,
    link: 'https://www.linkedin.com/company/akwadux',
    title: 'linkedin',
  },
  {
    icon: tiktokIcon,
    link: 'https://www.tiktok.com/@akwadux?is_from_webapp=1&sender_device=pc',
    title: 'tiktok',
  },
  {
    icon: instagramIcon,
    link: 'https://www.instagram.com/akwadux?igsh=c2FncTI2NmVqYmZi',
    title: 'instagram',
  },
  {
    icon: xIcon,
    link: 'https://x.com/AkwadUX/status/1875563033449443701',
    title: 'x',
  },
  {
    icon: youtubeIcon,
    link: 'https://youtube.com/@akwadux?si=5b276P6s8bmAkQXa',
    title: 'youtube',
  }
];
</script>
<style lang="scss">
.whatsapp-cta{
  text-decoration: none !important;
  padding-left: 18px;
  i{
    margin-right: 5px;
  }
}

.q-footer {
  padding-bottom: 50px;
  padding-top: 80px;
  color: #656567;
  position: absolute;
  max-height: auto;
  overflow: hidden;
  z-index:1;
  &>div {
    @media (max-width: 870px) {
      justify-content: center;
    }
  }

  a:hover {
    color: $primary;
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  @media (min-width: 1024px) {
    .br {
      display: none;
    }

  }

  .footer-content {
    position: absolute;
    width: 50%;
    padding-top: 10px;

    @media (min-width: 870px) {
      left: 20px;
    }

    @media (min-width: 1300px) {
      left: 200px;
    }

    @media (max-width: 870px) {
      width: 100%;
      position: relative;
      padding: 20px 5px 30px;
      justify-content: center;
    }


    i {
      vertical-align: middle;
    }

    .social-icons {
      position: relative;
      background-color: #FFF;
      padding: 10px;

      @media (min-width: 870px) {
        bottom: -45px;
      }

      @at-root {
        .bg-f5 & {
          // margin-top: 10px;
          background-color: #f5f5f5;
        }
      }

      a {
        text-decoration: none;

        &:hover path {
          stroke: $primary
        }
      }

      i,
      svg {
        font-size: 18px;
        margin: 0 2px;
        color: #656567;

        &:hover {
          color: $primary;
        }
      }
    }



  }

  .contact-text {
    border-left: 1px solid #e0e0e0;
    margin-left: 20px;
    padding-left: 20px;

    @media (max-width: 1024px) {
      margin-left: 14px;
      padding-left: 14px;
    }

    ul {
      list-style: none;
      margin: 5px 0 0;
      padding: 0;

      li {
        margin-bottom: 3px;
      }
    }

  }

  .dark-logo {
    text-align: center;

    img {
      height: 35px;
      display: block;
      margin: auto;

      &:nth-child(2) {
        position: relative;
        right: -60px;
      }
    }
  }

  .line-border {
    display: inline-block;
    border-bottom: 1px solid #696969;
    flex-grow: 1;
    height: 10px;

    @media (min-width: 870px) {
      box-shadow: inset 0px -1px 1px -1px #696969;
    }

    @media (max-width: 870px) {
      border-bottom: 1px solid #9e9e9e;
    }
  }

  img.footer-img {
    margin-bottom: -1px;
    height: 140px;
    max-width: 100%;

    @media (max-width: 870px) {
      margin-bottom: -1px;
    }

    @media (max-width: 600px) {
      max-height: 100px;
    }

    @media (min-width: 870px) and (max-width: 1050px) {
      margin-right: -120px;
    }
  }

  .full-width {
    margin-bottom: -50px;
  }
}
.icon-social:hover img {
  filter:
    brightness(0)
    saturate(100%)
    invert(39%)
    sepia(98%)
    saturate(1353%)
    hue-rotate(214deg)
    brightness(104%)
    contrast(103%);
}
</style>
