import type { IAPIRes } from '@/types/IAPIRes';
import { useAxios } from '@/boot/axios';
import { INotificationAlert, INotificationResponse } from '@/types/INotifications';

export const getAlertsNumber = async ( ): Promise<IAPIRes<INotificationAlert>> => {
  const { axiosAuth } = useAxios();
  const response = await axiosAuth.get<IAPIRes<INotificationAlert>>(
    '/notifications/alert/get',
  );
  return response.data;
};

export const getNotifications = async (page: number): Promise<IAPIRes<INotificationResponse>> => {
  const { axiosAuth } = useAxios();
  const response = await axiosAuth.get<IAPIRes<INotificationResponse>>(`/notifications/get?page=${page}`);
  return response.data;
};

export const markAlertAsRead = async (): Promise<IAPIRes<any>> => {
  const { axiosAuth } = useAxios();
  const response = await axiosAuth.get<IAPIRes<any>>(
    '/notifications/alert/read',
  );
  return response.data;
};

export const viewNotification = async (
  notifyId: number,
): Promise<IAPIRes<any>> => {
  const { axiosAuth } = useAxios();
  const response = await axiosAuth.get<IAPIRes<any>>(
    `/notifications/view/${notifyId}`,
  );
  return response.data;
};
