import Pusher from 'pusher-js';
import { APP_BASE_URL } from '@/boot/axios';

export class PusherService {
  private static instance: PusherService;
  private pusher: Pusher | null = null;

  private constructor() {}

  public static getInstance(): PusherService {
    if (!PusherService.instance) {
      PusherService.instance = new PusherService();
    }
    return PusherService.instance;
  }

  public initializePusher(token: string): Pusher {
    if (!this.pusher) {
      this.pusher = new Pusher('cfac863ad39db578242d', {
        cluster: 'ap2',
        forceTLS: true,
        authEndpoint: `${APP_BASE_URL}/api/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
    }
    return this.pusher;
  }

  public subscribeToUserChannel(userId: number): any {
    if (!this.pusher) return null;
    return this.pusher.subscribe(`private-user-notification-${userId}`);
  }

  public disconnect(): void {
    if (this.pusher) {
      this.pusher.disconnect();
      this.pusher = null;
    }
  }
}
